/* TOP
------------------------------------------------- */
.section__title {
  margin-bottom: 60px;
  font-size: 3rem;
  color: $color-title;
  text-align: center;
  @include media(pc-l) {
    margin-bottom: 40px;
    font-size: 2.8rem;
  }
  @include media(sp) {
    margin-bottom: 30px;
    font-size: 1.8rem;
  }

  &--property {
    margin-bottom: 70px;
    @include media(pc-l) {
      margin-bottom: 50px;
    }
    @include media(sp) {
      margin-bottom: 40px;
    }
  }
}

/* greeting
------------------------------------------------- */
.greeting {
  padding: 100px 0 110px;
  text-align: center;
  opacity: 0;
  @include media(pc-l) {
    padding: 80px 0 90px;
  }
  @include media(tb) {
    padding: 80px 30px 90px;
  }
  @include media(sp) {
    padding: 70px 20px 74px;
  }

  &__text {
    margin-bottom: 50px;
    font-size: 1.8rem;
    line-height: 2.2;
    @include media(pc-l) {
      margin-bottom: 40px;
      font-size: 1.5rem;
    }
    @include media(sp) {
      margin-bottom: 30px;
      font-size: 1.3rem;
    }
  }

  .greeting-box {
    display: flex;
    justify-content: center;
    align-items: center;

    &__text {
      margin-right: 30px;
      font-size: 1.6rem;
      @include media(pc-l) {
        font-size: 1.4rem;
      }
      @include media(sp) {
        font-size: 1.3rem;
      }
    }

    &__name {
      width: 136px;
      @include media(pc-l) {
        width: 120px;
      }
      @include media(sp) {
        width: 100px;
      }
    }
  }
}

/* property
------------------------------------------------- */
.property {
  background-color: #f1f1f1;
  padding: 100px 0;
  @include media(pc-l) {
    padding: 80px 0;
  }
  @include media(sp) {
    padding: 70px 0;
  }

  .inner {
    max-width: 1200px;
    margin: 0 auto;
    @include media(pc-l) {
      padding: 0 30px;
    }
    @include media(sp) {
      padding: 0 10px;
    }
  }

  &-tabs {
    width: 100%;
    opacity: 0;

    &-head {
      display: flex;

      &__btn {
        position: relative;
        display: block;
        width: 50%;
        background: $color-wh;
        padding: 20px 0;
        font-size: 2rem;
        color: $color-blue;
        text-align: center;
        @include media(pc-l) {
          padding: 16px 0;
          font-size: 1.8rem;
        }
        @include media(sp) {
          padding: 14px 0;
          font-size: 1.6rem;
        }

        &:not(.btn-active) {
          cursor: pointer;
          &:hover {
            box-shadow: inset 2px 2px 2px rgba(0, 0, 0, 0.6), 0 0 1px rgba(0, 0, 0, 0);
          }
        }

        &.btn-active {
          background: $color-blue;
          color: $color-wh;
          &::after {
            content: "";
            position: absolute;
            bottom: -14px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 14px 14px 0 14px;
            border-color: $color-blue transparent transparent transparent;
            @include media(pc-l) {
              bottom: -12px;
              border-width: 12px 12px 0 12px;
            }
          }
        }
      }
    }

    &-content {
      background: $color-wh;
      padding: 40px;
      border: 2px solid $color-blue;
      @include media(pc-l) {
        padding: 30px;
      }
      @include media(sp) {
        padding: 30px 10px;
      }

      .tabs {
        display: none;
        &.tab-active {
          display: block;
          animation: fadein ease 1s forwards;
        }
      }

      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: -30px;
        @include media(tb) {
          justify-content: flex-start;
          margin-left: -30px;
        }
        @include media(sp) {
          margin-top: -20px;
          margin-left: -10px;
        }
        &::before {
          content: "";
          display: block;
          width: 23%;
          order: 1;
          @include media(tb) {
            display: none;
          }
        }
        &::after {
          content: "";
          display: block;
          width: 23%;
          @include media(tb) {
            display: none;
          }
        }

        &-item {
          flex-basis: 23%;
          margin-top: 30px;
          @include media(tb) {
            flex-basis: auto;
            width: calc((100% - 60px) / 2);
            margin-left: 30px;
          }
          @include media(sp) {
            width: calc((100% - 20px) / 2);
            margin-top: 20px;
            margin-left: 10px;
          }

          &__images {
            margin-bottom: 12px;
            @include media(pc-l) {
              margin-bottom: 10px;
            }
            @include media(sp) {
              margin-bottom: 8px;
            }
          }

          &__name {
            margin-bottom: 8px;
            font-size: 1.7rem;

            @include media(pc-l) {
              margin-bottom: 6px;
              font-size: 1.6rem;
            }

            @include media(sp) {
              margin-bottom: 5px;
              font-size: 1.5rem;
            }
          }

          &__address {
            font-size: 1.5rem;
            line-height: 1.4;
            @include media(pc-l) {
              font-size: 1.4rem;
            }
            @include media(sp) {
              font-size: 1.3rem;
            }
          }
        }

        &--sub {
          margin-bottom: 0 !important;

          .property-tabs-content-list-item {
            position: relative;
            margin-bottom: 28px;
            padding-left: 18px;
            @include media(pc-l) {
              margin-bottom: 24px;
              padding-left: 16px;
            }
            @include media(sp) {
              margin-bottom: 20px;
              padding-left: 10px;
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 6px;
              height: 100%;
              background-color: $color-blue;
              @include media(pc-l) {
                width: 4px;
              }
              @include media(sp) {
                width: 3px;
              }
            }

            &__name {
              margin-bottom: 12px;
              @include media(pc-l) {
                margin-bottom: 10px;
              }
              @include media(sp) {
                margin-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
}

/* about
------------------------------------------------- */
.about {
  padding: 100px 0 0;
  opacity: 0;
  @include media(pc-l) {
    padding: 80px 0 0;
  }
  @include media(sp) {
    padding: 70px 0 0;
  }

  &-list {
    max-width: 720px;
    margin: 0 auto 60px;
    @include media(pc-l) {
      max-width: 600px;
      margin: 0 auto 50px;
    }
    @include media(tb) {
      padding: 0 30px;
    }
    @include media(sp) {
      margin-bottom: 30px;
      padding: 0 20px;
    }

    &-item {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #969696;
      font-size: 1.7rem;
      @include media(pc-l) {
        padding: 20px 0;
        font-size: 1.5rem;
      }
      @include media(sp) {
        padding: 16px 0;
        font-size: 1.3rem;
      }
      &:last-child {
        border-bottom: none;
      }

      &__title {
        width: 250px;
        padding-left: 10px;
        font-size: 1.7rem;
        line-height: 1.6;
        @include media(pc-l) {
          width: 200px;
          font-size: 1.5rem;
        }
        @include media(tb) {
          width: 180px;
        }
        @include media(sp) {
          width: 130px;
          padding-left: 6px;
          font-size: 1.3rem;
        }
        @include media(se) {
          width: 110px;
        }
      }

      &__content {
        line-height: 1.6;
      }

      .in-sp {
        display: none;
        @include media(sp) {
          display: inline;
        }
      }
    }
  }

  &-map {
    iframe {
      width: 100%;
      height: 420px;
      @include media(pc-l) {
        height: 320px;
      }
      @include media(sp) {
        height: 240px;
      }
    }
  }
}

/* affiliate
------------------------------------------------- */
.affiliate {
  padding: 60px 0;
  @include media(pc-l) {
    padding: 40px 0;
  }
  @include media(sp) {
    padding: 34px 0 40px;
  }

  &-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    @include media(pc-l) {
      padding: 0 30px;
    }
    @include media(sp) {
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 20px;
    }

    &__item {
      @include media(sp) {
        img {
          margin: 0 auto;
        }
      }

      &--01 {
        width: 240px;
        @include media(pc-l) {
          width: 210px;
        }
        @include media(tb) {
          width: 160px;
        }
        @include media(sp) {
          width: 50%;
          margin-bottom: 40px;
          img {
            width: 146px;
            margin: 0 auto;
          }
        }
      }
      &--02 {
        width: 170px;
        @include media(pc-l) {
          width: 150px;
        }
        @include media(tb) {
          width: 120px;
        }
        @include media(sp) {
          width: 50%;
          margin-bottom: 40px;
          img {
            width: 112px;
          }
        }
      }
      &--03 {
        width: 132px;
        @include media(pc-l) {
          width: 100px;
        }
        @include media(tb) {
          width: 90px;
        }
        @include media(sp) {
          width: 50%;
          img {
            width: 80px;
          }
        }
      }
      &--04 {
        width: 178px;
        @include media(pc-l) {
          width: 140px;
        }
        @include media(tb) {
          width: 110px;
        }
        @include media(sp) {
          width: 50%;
          img {
            width: 108px;
          }
        }
      }

      a {
        display: block;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

.btnArea {
  .link-btn {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    z-index: 50;
    a {
      display: block;
      background-color: $color-blue;
      padding: 40px 28px 40px 30px;
      font-size: 2rem;
      color: $color-wh;
      font-feature-settings: normal;
      @include media(pc-l) {
        padding: 30px 24px 30px 22px;
        font-size: 1.8rem;
      }

      &:hover {
        background-color: $color-wh;
        border: 1px solid $color-blue;
        border-right: none;
        color: $color-blue;
      }
    }
  }

  .sp-btn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 51;

    .sp-btn-list {
      display: flex;
      background-color: #efefef;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      &__item {
        width: 50%;
        border-right: 1px solid rgba(0, 0, 0, 0.8);
        text-align: center;

        a {
          display: block;
          padding: 22px 0;
          font-size: 1.3rem;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
