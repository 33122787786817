/*ここに変数を定義*/

//font-family
$font-family: "A1 Mincho";

//font-size
$font-size: 10px;

// カラー
$color-bk: #333;
$color-wh: #fff;
$color-title: #c08b00;
$color-blue: #003f6c;

// clearfix  ※floatを使用した親要素に
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

// aタグの範囲を全体へ ※親要素にpoition: reletive;
@mixin linkall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// position-center ※親要素にpoition: reletive;
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
