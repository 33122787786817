/* メインの設定(全体)
------------------------------- */

/* wrapper */
.wrapper {
  max-width: 880px;
  margin: 0 auto;
  @include media(pc-l) {
    max-width: 880px;
  }
  @include media(tb) {
    max-width: initial;
    padding: 0 50px;
  }
  @include media(sp) {
    padding: 0 35px;
  }
}

body {
  @include media(se) {
    min-width: 360px;
  }
}

// br関連
.d-tb,
.d-sp {
  display: none;
}
@include media(sp) {
  .d-pc {
    display: none;
  }
  .d-sp {
    display: block;
  }
}

// wrapperから解放
.rebirth-m {
  //.wrapperから出したい(width:100%にしたい)場合ネガティブマージンを使う。
  margin: 0 calc(50% - 50vw);
}

.rebirth-p {
  //.wrapperから出すがpaddingは.wrapperに合わせたい場合(主に背景色だけ100%に使う事が多い)
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
}
