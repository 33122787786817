
/* contactページ
------------------------------------------------ */
.contact-header {
    .section__title--contact {
        position: relative;
        margin-bottom: 50px;
        padding-bottom: 60px;
        @include media(tb) {
            margin-bottom: 40px;
            padding-bottom: 46px;
        }
        @include media(sp) {
            margin-bottom: 30px;
            padding-bottom: 36px;
        }
    
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 60px;
            height: 1px;
            background-color: $color-bk;
            @include media(sp) {
                width: 30px;
            }
        }
    }
}

/* header
------------------------------------------------ */
.header--contact {
    height: auto;
    padding-bottom: 130px;
    border-bottom: 1px solid #cccccc;
    @include media(pc-l) {
        padding-bottom: 90px;
    }
    @include media(tb) {
        padding-bottom: 80px;
    }
    @include media(sp) {
        
    }

    .header__text {
        margin-bottom: 80px;
        @include media(pc-l) {
            margin-bottom: 70px;
        }
        @include media(sp) {
            margin-bottom: 50px;
        }
    }

    .contact__logo {
        width: 190px;
        margin: 0 auto;
        @include media(pc-l) {
            width: 150px;
        }
        @include media(sp) {
            position: relative;
            top: auto;
            left: auto;
            transform: translateX(0);
            width: 100px;
            margin: 0 auto 60px;
        }
    }
}

/* contact
------------------------------------------------ */
.contact {
    max-width: 800px;
    margin: 0 auto;
    padding: 140px 0 180px;
    @include media(tb) {
        padding: 120px 30px 140px;
    }
    @include media(sp) {
        padding: 100px 20px 120px;
    }

    .contact-header {
        margin-bottom: 80px;
        @include media(tb) {
            margin-bottom: 70px;
        }
        @include media(sp) {
            margin-bottom: 60px;
        }

        &__text {
            font-size: 1.6rem;
            line-height: 2;
            text-align: center;
            @include media(tb) {
                font-size: 1.5rem;
            }
            @include media(sp) {
                font-size: 1.3rem;
            }

            .color-red {
                color: #ca0012;
            }
        }
    }

    .contact-main {

        .form {

            .row {
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                @include media(tb) {
                    flex-wrap: wrap;
                }
                @include media(sp) {
            
                }

                &--big {
                    margin-bottom: 36px;
                }
                &--small {
                    margin-bottom: 20px;
                    @include media(tb) {
                        margin-bottom: 10px;
                    }
                    @include media(sp) {
                
                    }
                }
                &--add {
                    @include media(tb) {
                        margin-bottom: 10px;
                    }
                }
                &--textarea {
                    align-items: flex-start;
                    margin-bottom: 36px;
                }
            }

            &__title {
                @include clearfix;
                width: 300px;
                font-size: 1.6rem;
                @include media(tb) {
                    width: 100%;
                    margin-bottom: 8px;
                }
                @include media(sp) {
                    margin-bottom: 6px;
                    font-size: 1.5rem;
                }

                &--textarea {
                    margin-top: 6px;
                }

                &-req {
                    &::after {
                        content: "必須";
                        margin-left: 16px;
                        font-size: 1.3rem;
                        color: #ca0012;
                        @include media(sp) {
                            font-size: 1.2rem;
                        }
                    }
                }

                &-kome {
                    &::after {
                        content: "必須※";
                        margin-left: 16px;
                        font-size: 1.3rem;
                        color: #ca0012;
                        @include media(sp) {
                            font-size: 1.2rem;
                        }
                    }
                }

                .form__text {
                    float: right;
                    margin-right: 8px;
                    font-size: 1.5rem;
                    @include media(tb) {
                        float: none;
                        display: block;
                        margin-top: 16px;
                        margin-right: 0;
                        font-size: 1.4rem;
                    }
                    @include media(sp) {
                
                    }
                }
            }

            &__input {
                position: relative;
                flex: 1;

                input, textarea {
                    width: 100%;
                    background-color: $color-wh;
                    padding: 4px 5px;
                    border: 1px solid #666;
                    line-height: 1.6;
                }

                &--yubin {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    @include media(tb) {
                        flex-wrap: wrap;
                    }
                    @include media(sp) {
                
                    }

                    input {
                        width: 260px;
                        @include media(tb) {
                            width: 100%;
                        }
                        @include media(sp) {
                    
                        }
                    }

                    .addBtn {
                        background-color: #666;
                        padding: 10px 30px;
                        font-size: 1.4rem;
                        color: $color-wh;
                        cursor: pointer;
                        transition: .3s;
                        @include media(tb) {
                            margin-top: 10px;
                            padding: 11px 30px;
                        }
                        @include media(sp) {
                            padding: 11px 26px;
                            font-size: 1.3rem;
                        }

                        &:hover {
                            background-color: #ccc;
                        }
                    }
                }

            }

            &__radio {
                position: relative;
                display: flex;
                @include media(tb) {
                    margin-top: 10px;
                }
                @include media(sp) {
            
                }

                label {
                    display: flex;
                    align-items: center;
                }
                
                input {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }

                .mwform-radio-field-text {
                    margin-right: 14px;
                    font-size: 1.6rem;
                    @include media(sp) {
                        font-size: 1.5rem;
                    }
                }
            }

            &__comment {
                font-size: 1.4rem;
                line-height: 1.6;
                color: #ca0012;
                @include media(sp) {
                    font-size: 1.2rem;
                }
            }

            .privacy {
                width: 100%;
                margin-bottom: 60px;
                @include media(sp) {
                    margin-bottom: 70px;
                }

                .privacy-box {
                    height: 220px;
                    margin-bottom: 40px;
                    padding: 34px 25px;
                    border: 1px solid #666;
                    overflow: scroll;
                    @include media(sp) {
                        height: 200px;
                        margin-bottom: 30px;
                        padding: 30px 20px;
                    }
                    
                    &__title {
                        margin-bottom: 26px;
                        font-size: 1.8rem;
                        text-align: center;
                        @include media(sp) {
                            margin-bottom: 20px;
                            font-size: 1.6rem;
                        }
                    }

                    &__text {
                        font-size: 1.4rem;
                        line-height: 2;
                        @include media(sp) {
                            font-size: 1.3rem;
                        }
                    }
                }

                &__accept {
                    position: relative;

                    label{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.6rem;
                        @include media(sp) {
                            font-size: 1.5rem;
                        }
                    }

                    input {
                        display: block;
                        width: 15px;
                        height: 15px;
                        margin-right: 7px;
                        
                    }
                }
            }
        }

        .submit {
            text-align: center;

            .submitBtn {
                width: 400px;
                background-color: $color-bk;
                margin: 0 auto;
                padding: 22px 0;
                font-size: 1.8rem;
                font-family: $font-family;
                color: $color-wh;
                transition: .3s;
                cursor: pointer;
                @include media(sp) {
                    width: 100%;
                    padding: 20px 0;
                    font-size: 1.6rem;
                }

                &:hover {
                    background-color: #ccc;
                }
            }

            &__text {
                margin-top: 40px;
                font-size: 1.4rem;
                text-align: center;
                @include media(sp) {
                    margin-top: 24px;
                    font-size: 1.2rem;
                    line-height: 1.8;
                }
            }
        }
    }

    .mw_wp_form .error {
        position: absolute;
        bottom: -15px;
        left: 0;
        font-size: 1.2rem;
        color: red;
        @include media(sp) {
            font-size: 1.1rem;
        }
    }
}



/* contact-confirm
------------------------------------------------ */
.contact--confirm {
    max-width: 680px;

    .contact-header__title {
        margin-bottom: 22px;
        font-size: 2.2rem;
        font-weight: bold;
        text-align: center;
        @include media(sp) {
            margin-bottom: 12px;
            font-size: 1.6rem;
        }
    }

    .contact-main {
        .form {
            .row {
                margin-bottom: 44px;
                @include media(sp) {
                    margin-bottom: 36px;
                }

                &--big {
                    margin-bottom: 44px;
                    @include media(sp) {
                        margin-bottom: 36px;
                    }
                }
                &--small {
                    margin-bottom: 40px;
                    @include media(tb) {
                        margin-bottom: 40px;
                    }
                    @include media(sp) {
                        margin-bottom: 36px;
                    }
                }
                &--add {
                    margin-bottom: 30px;
                    @include media(sp) {
                        margin-bottom: 10px;
                    }
                }
                &--dummy {
                    display: none;
                }
                &--textarea {
                    margin-bottom: 100px;
                    @include media(sp) {
                        margin-bottom: 70px;
                    }
                }
            }

            &__title {
                width: 260px;
                font-size: 1.6rem;
                @include media(tb) {
                    margin-bottom: 14px;
                }
                @include media(sp) {
                    margin-bottom: 10px;
                }

                &--textarea {
                    @include media(tb) {
                       margin-top: 0;
                    }
                }

                &-req {
                    &::after {
                        display: none;
                    }
                }

                &-kome {
                    &::after {
                        display: none;
                    }
                }

                .form__text {
                    display: none;
                    @include media(tb) {
                        display: none;
                    }
                }
            }

            &__input {
                font-size: 1.6rem;
                @include media(sp) {
                    font-size: 1.5rem;
                }

                &--yubin {

                    .addBtn {
                        display: none;
                    }
                }

                &--textarea {
                    line-height: 1.6;
                }

            }

            &__radio {
                font-size: 1.6rem;
                @include media(tb) {
                    margin-top: 0;
                }
                @include media(sp) {
                    font-size: 1.5rem;
                }
            }

            .privacy {
                display: none;
            }
        }

        .submit {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .submitBtn {
                width: 48%;
                @include media(sp) {
                    width: 100%;
                    padding: 18px 0;
                    font-size: 1.6rem;
                }

                &--back {
                    background-color: $color-wh;
                    border: 1px solid #a8a8a8;
                    color: $color-bk;
                    @include media(sp) {
                        margin-bottom: 12px;
                    }
                }
            }

            &__text {
                width: 100%;
            }
        }
    }
}




/* contact-thanks
------------------------------------------------ */
.contact--thanks {

    .contact-header {
        .section__title--contact {
            margin-bottom: 0;
        }
    }

    .contact-thanks__text {
        margin-bottom: 160px;
        font-size: 1.8rem;
        line-height: 2;
        text-align: center;
        @include media(tb) {
            margin-bottom: 120px;
            font-size: 1.6rem;
        }
        @include media(sp) {
            margin-bottom: 100px;
            font-size: 1.3rem;
        }
    }

    .topBtn {
        text-align: center;

        a {
            display: block;
            width: 400px;
            background-color: $color-bk;
            margin: 0 auto;
            padding: 22px 0;
            font-size: 1.8rem;
            color: $color-wh;
            @include media(sp) {
                width: 100%;
                padding: 20px 0;
                font-size: 1.6rem;
            }

            &:hover {
                background-color: #ccc;
            }
        }
        
        &__text {
            margin-top: 40px;
            font-size: 1.4rem;
            text-align: center;
            @include media(sp) {
                margin-top: 24px;
                font-size: 1.2rem;
                line-height: 1.8;
            }
        }
    }
}