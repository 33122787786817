.opening {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $color-wh;
    z-index: 102;

    &__text {
        display: none;
        font-size: 2.4rem;
        line-height: 2;
        @include media(pc-l) {
            font-size: 2.2rem;
        }
        @include media(tb) {
            font-size: 2rem;
        }
        @include media(sp) {
            visibility: hidden;
        }

        &--sp {
            visibility: hidden;
            @include media(sp) {
                visibility: visible;
                font-size: 1.6rem;
                text-align: center;
            }
        }

        span {
            opacity: 0;
        }
    }
}

.contact-fadeOut {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-wh;
    z-index: 102;
}

.mainSite {
    position: relative;
}