/* footer
------------------------------------------------ */
.footer{
    background-color: $color-blue;
    padding: 50px 0 16px;
    color: $color-wh;
    @include media(pc-l) {
        padding: 40px 0 10px;
    }
    @include media(sp) {
        padding: 40px 0 70px;
    }

    &-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto 30px;
        @include media(pc-l) {
            margin-bottom: 20px;
            padding: 0 30px;
        }
        @include media(sp) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 24px;
        }

        &__logo {
            width: 150px;
            margin-left: 30px;
            @include media(pc-l) {
                width: 120px;
                margin-left: 20px;
            }
            @include media(sp) {
                width: 90px;
                margin-bottom: 30px;
                margin-left: 0;
            }

            a {
                display: block;
                &:hover {
                    opacity: 0.8;
                }
            }
        }

        &-content {

            &-list {
                display: flex;
                align-items: center;
                margin-bottom: 36px;
                font-size: 1.6rem;
                @include media(pc-l) {
                    margin-bottom: 30px;
                    font-size: 1.3rem;
                }
                @include media(sp) {
                    display: none;
                }

                &__item {
                    margin-right: 30px;
                    @include media(pc-l) {
                        margin-right: 24px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        position: relative;
                        display: inline-block;
                        padding-bottom: 6px;
                        transition: .3s;
                      }
                      a::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 1px;
                        background-color: $color-wh;
                        transition: .3s;
                      }
                      a:hover::after {
                        width: 100%;
                      }
                }
            }

            &-box {
                text-align: right;

                &__title {
                    margin-bottom: 14px;
                    font-size: 1.8rem;
                    @include media(pc-l) {
                        margin-bottom: 10px;
                        font-size: 1.5rem;
                    }
                    @include media(sp) {
                        display: none;
                    }
                }

                &__address {
                    font-size: 1.6rem;
                    line-height: 1.8;
                    @include media(pc-l) {
                        font-size: 1.3rem;
                    }
                    @include media(sp) {
                        text-align: center;
                    }
                }
            }
        }
    }

    .copyright {
        font-size: 1.4rem;
        text-align: center;
        @include media(pc-l) {
            font-size: 1.1rem;
        }
        @include media(sp) {
            font-size: 1rem;
        }
    }
}