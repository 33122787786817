/* header
------------------------------------------------ */
.header {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 60px 60px 0;
  @include media(pc-l) {
    padding: 40px 40px 0;
  }
  @include media(tb) {
    padding: 20px 20px 0;
  }
  @include media(sp) {
    height: auto;
    padding: 16px 0 0;
  }

  &__text {
    font-size: 1.5rem;
    line-height: 1.6;
    @include media(pc-l) {
      margin-top: 16px;
      font-size: 1.4rem;
    }
    @include media(tb) {
    }
    @include media(sp) {
      margin-top: 0;
      margin-bottom: 60px;
      padding: 0 14px;
      font-size: 1.1rem;
    }
  }

  &__logo {
    position: absolute;
    top: 11%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    width: 208px;
    z-index: 300;
    @media screen and (min-width: 1241px) and (max-height: 1000px) {
      top: 11%;
      width: 160px;
    }
    @media screen and (min-width: 1241px) and (max-height: 800px) {
      top: 11%;
      width: 146px;
    }
    @include media(pc-l) {
      top: 12%;
      width: 180px;
    }
    @include media(tb) {
      top: 13%;
      width: 160px;
    }
    @include media(sp) {
      position: relative;
      top: auto;
      left: auto;
      transform: translateX(0);
      width: 108px;
      margin: 0 auto 70px;
    }
  }

  .swiper-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 60px;
    @include media(pc-l) {
      padding: 0 40px;
    }
    @include media(tb) {
      padding: 0 20px;
    }
    @include media(sp) {
      position: static;
      transform: translateX(0);
      padding: 0;
    }

    img {
      object-fit: cover;
      font-family: "object-fit: cover;";
      height: 800px;
      @media screen and (max-height: 1180px) {
        height: 650px;
      }
      @media screen and (max-height: 1000px) {
        height: 550px;
      }
      @media screen and (max-height: 900px) {
        height: 500px;
      }
      @media screen and (max-height: 800px) {
        height: 440px;
      }

      @include media(sp) {
        width: 100%;
        height: 240px !important;
      }
    }
  }
}

@media screen and (orientation: landscape) {
  .opening {
    @media screen and (max-width: 813px) {
      visibility: hidden;

      &__text {
        visibility: hidden;
      }
    }
  }

  .header {
    @media screen and (max-width: 813px) {
      height: auto;

      &__text {
        margin-bottom: 50px;
      }

      &__logo {
        position: relative;
        top: auto;
        left: auto;
        transform: translateX(0);
        display: block;
        width: 110px;
        margin: 0 auto 50px;
      }

      .swiper-container {
        position: static;
        transform: translateX(0);
        padding: 0;
      }
    }
  }

  .btnArea {
    @media screen and (max-width: 813px) {
      visibility: hidden;
    }
  }
}

/* ドロワー
------------------------------------------------ */
.drawer-btn {
  position: fixed;
  top: 40px;
  right: 60px;
  width: 58px;
  height: 58px;
  background: $color-wh;
  cursor: pointer;
  z-index: 101;
  @include media(tb) {
    top: 16px;
    right: 20px;
  }
  @include media(sp) {
    top: 0;
    right: 0;
  }
}

.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}

.menu-trigger {
  @include center;
  width: 30px;
  height: 22px;
  z-index: 5;

  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-bk;
    border-radius: 4px;

    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 10px;
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}

.drawer-btn.active {
  .menu-trigger span {
    &:nth-of-type(1) {
      transform: translateY(10px) rotate(-315deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-10px) rotate(315deg);
    }
  }
}

.drawer-btn:not(.active):hover {
  .menu-trigger span {
    &:nth-of-type(2) {
      width: 70%;
    }
    &:nth-of-type(3) {
      width: 35%;
    }
  }
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 1;

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

.drawer-modal {
  position: fixed;
  top: 0;
  right: -500px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
  overflow: scroll;
  z-index: 100;
  @include media(pc-l) {
    right: -440px;
    width: 440px;
  }
  @include media(sp) {
    flex-direction: column;
    right: -400px;
    width: 100%;
  }

  &.open {
    right: 0;
    transition: all 0.5s;
    visibility: visible;
    opacity: 1;
  }

  &__logo {
    display: none;
    @include media(sp) {
      display: block;
      width: 100px;
      margin: 0 auto 16%;
    }
  }

  .drawer-modal-list {
    @include media(sp) {
      position: static;
      transform: none;
    }

    &__item {
      margin-bottom: 70px;
      font-size: 2.2rem;
      opacity: 0;
      &:last-child {
        margin-bottom: 0;
      }
      @include media(pc-l) {
        margin-bottom: 60px;
        font-size: 2rem;
      }
      @include media(sp) {
        margin-bottom: 34px;
        font-size: 1.6rem;
        text-align: center;
      }

      a {
        position: relative;
        display: inline-block;
        padding-bottom: 8px;
        transition: 0.3s;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0;
          height: 1px;
          background-color: $color-bk;
          transition: 0.3s;
        }

        &:hover::after {
          width: 100%;
          @include media(sp) {
            width: 0;
          }
        }
      }
    }
  }
}
